import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import { Dialog, Drawer, Tooltip, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import {
  CanvasBox,
  CloseButton,
  DescriptionButton,
  DialogBox,
  InfoButton,
} from '../../styles/global.styles';
import { Feature, GeoJson } from '../../types/geojson.interface';
import ThreeDWrapper from '../three-d-wrapper/three-d-wrapper.component';

interface Props {
  geojson: GeoJson;
}

const DetailsModal: React.FC<Props> = ({ geojson }) => {
  const { id } = useParams();
  const [current] = useState(
    geojson.features.find((f) => f.properties.name === id) ??
    ({
      type: 'Feature',
      properties: {
        name: '',
        description: '',
        model: '',
      },
      geometry: {
        type: 'Point',
        coordinates: [],
      },
    } as Feature)
  );
  const [showDescription, setShowDescription] = useState(false);
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  return (
    <Dialog fullScreen onClose={() => navigate('/')} open={true} maxWidth="xl">
      <Helmet title={current.properties.name} />
      <CloseButton onClick={() => navigate('/')}>
        <CloseIcon />
      </CloseButton>
      <DialogBox>
        <CanvasBox ref={canvasRef}></CanvasBox>
        <ThreeDWrapper canvas={canvasRef} feature={current} />
        <Drawer
          anchor="right"
          open={showDescription}
          onClose={() => setShowDescription(false)}
          sx={{ zIndex: 1400, width: '50vw', left: 'unset' }}
          elevation={24}
          PaperProps={{
            sx: { width: '50vw', left: 'unset', padding: '2rem' },
          }}
        >
          <CloseButton onClick={() => setShowDescription(false)}>
            <CloseIcon />
          </CloseButton>
          <Typography variant='h3'>Description</Typography>
          {current.properties.description.split('\n').map(p => <Typography sx={{ mb: 3 }}>{p}</Typography>)}
        </Drawer>
        <DescriptionButton
          startIcon={<DescriptionIcon />}
          variant="outlined"
          onClick={() => setShowDescription(!showDescription)}
        >
          {showDescription ? 'Hide' : 'Description'}
        </DescriptionButton>
        <Tooltip
          title="Click and drag to rotate model, use mouse scroll to zoom in or out."
          arrow
        >
          <InfoButton>
            <InfoIcon />
          </InfoButton>
        </Tooltip>
      </DialogBox>
    </Dialog>
  );
};

export default DetailsModal;
